.loader-container {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    z-index: 1099;
    opacity: 0.8;
  
    img {
      display: block;
      left: 50%;
      top: 50%;
      display: block;
      position: absolute;
      transform: translate(-50%, -50%);
    }
  }
  